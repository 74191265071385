import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Epistemic = makeShortcode("Epistemic");
const CodesandboxIframe = makeShortcode("CodesandboxIframe");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Epistemic effort mdxType="Epistemic">
  I've spent a good amount of time discussing it. I'm willing to engage in a
  conversation if you'd like to teach me something new on the matter or
  prove me wrong.
    </Epistemic>
    <p>{`I’m starting with reasons why I think PropTypes are not good enough, and
later I’m showing what TypeScript gives you to solve these problems and
improve your React code even more on top of it.`}</p>
    <p>{`I’d like to be clear — I’m not bashing
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/prop-types"
      }}>{`prop-types`}</a>{` — It’s a really good
library, but the last publish was 9 months ago. As I’m writing this, it’s
November 2019, and there are much better alternatives for prop types.`}</p>
    <p>{`I’ve chosen TypeScript because of its popularity, but my arguments fit any
language with first-class type composition you can use to build React apps
(Flow,`}{` `}{`Reason, Kotlin, Scala).`}</p>
    <h2 {...{
      "id": "why",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why",
        "aria-label": "why permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Why?`}</h2>
    <h3 {...{
      "id": "its-easy-to-half-ass-proptypes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#its-easy-to-half-ass-proptypes",
        "aria-label": "its easy to half ass proptypes permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`It’s easy to half-ass PropTypes`}</h3>
    <p>{`I’ve seen too many of lines with
`}<inlineCode parentName="p">{`//eslint-disable-next-line react/forbid-prop-types`}</inlineCode>{`. Few codebases leverage
PropTypes to their full potential — mostly libraries (see `}<a parentName="p" {...{
        "href": "https://github.com/reach/reach-ui/blob/master/packages/tabs/src/index.js#L68"
      }}>{`Reach UI tabs`}</a>{`).`}</p>
    <p>{`I find exporting propTypes uncommon. Instead of using exported common types,
developers either use PropTypes.object or copy PropTypes.shape from another
component.`}</p>
    <p>{`Maybe it is hard to remember that you strip them out in production build,
and that’s why the devs I’ve met don’t want to make them too big and heavy?`}</p>
    <h3 {...{
      "id": "proptypesfunc-is-just-not-enough",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#proptypesfunc-is-just-not-enough",
        "aria-label": "proptypesfunc is just not enough permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`PropTypes.func is just not enough`}</h3>
    <p>{`Functions make stuff happen. They are pretty important. Types of functions
are important too. Stating that a prop is just a function, doesn’t document
intent. You still need to read the implementation to get the slightest idea
of what’s happening.`}</p>
    <figure>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "kt"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`interface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` VideoListProps : RProps {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`var`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` videos: List<Video>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`var`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` selectedVideo: Video?`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`var`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` onSelectVideo: (Video) -> Unit`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></code></pre>
      <figcaption>
        <p>{`copied from
`}<a parentName="p" {...{
            "href": "https://play.kotlinlang.org/hands-on/Building%20Web%20Applications%20with%20React%20and%20Kotlin%20JS/05_Working_Together_Composing_Components"
          }}>{`Kotlin React tutorial`}</a></p>
      </figcaption>
    </figure>
    <p>{`Take a look at the props above. `}<inlineCode parentName="p">{`onSelectVideo`}</inlineCode>{` takes a video and returns a
unit. This is a lot more information than ”`}<inlineCode parentName="p">{`onSelectVideo`}</inlineCode>{` is a function”.
We could argue that the name of the function should be enough, but what if a
possibility to select multiple videos was added later, as an additional
feature? If someone forgot to change the function name, `}<inlineCode parentName="p">{`PropTypes.func`}</inlineCode>{`
would still fit, and some other poor soul would get surprised by a runtime
error.`}</p>
    <h3 {...{
      "id": "optional-is-a-bad-default-for-application-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#optional-is-a-bad-default-for-application-code",
        "aria-label": "optional is a bad default for application code permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Optional is a bad default for application code`}</h3>
    <p>{`I do agree that nullable by default is a good design choice in some cases.
GraphQL is a `}<a parentName="p" {...{
        "href": "https://github.com/graphql/graphql-spec/issues/63"
      }}>{`perfect example`}</a>{`. Responses stitched from many data stores may
return partial data. This is the complexity we have to handle.`}</p>
    <p>{`And I’d say we have about enough of it. We should avoid introducing more
complexity ourselves. Every optional field without a default of the same
type increases cyclomatic complexity.`}</p>
    <figure>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "js"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk9"
            }}>{`Person`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`propTypes`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  car: `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`PropTypes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`shape`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`({`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    registrationNumber: `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`PropTypes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    year: `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`PropTypes`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  }),`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`};`}</span></span></code></pre>
      <figcaption>
        <p>{`source:
`}<a parentName="p" {...{
            "href": "https://codeburst.io/validating-props-easily-with-react-proptypes-96e80208207"
          }}>{`first blogpost`}</a>{`
in google search results for “PropTypes isRequired”`}</p>
      </figcaption>
    </figure>
    <p>{`Does this person have a car? Maybe. I live in a big city; I don’t have one
too. But is an empty object `}<inlineCode parentName="p">{`{}`}</inlineCode>{` really a valid car for our app? Do we
display an error message here? Did we just forget to write `}<inlineCode parentName="p">{`isRequired`}</inlineCode>{`, or
are we okay with cars without license plates?`}</p>
    <p>{`Typing `}<inlineCode parentName="p">{`isRequired`}</inlineCode>{` is yet another small decision for a programmer. The fact
that stating that a prop is nullable is an easier way allows to accidentally
introduce complexity. `}<inlineCode parentName="p">{`isOptional`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`isRequired`}</inlineCode>{` would be a better
API design.`}</p>
    <h2 {...{
      "id": "type-props--",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#type-props--",
        "aria-label": "type props   permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`type Props = ?`}</h2>
    <p>{`TypeScript is much better in describing React component props than
PropTypes. Let’s look at how it solves the problems I’ve mentioned before.`}</p>
    <h3 {...{
      "id": "easy-to-half-ass",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#easy-to-half-ass",
        "aria-label": "easy to half ass permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a><em parentName="h3">{`easy to half-ass?`}</em></h3>
    <p>{`Add `}<inlineCode parentName="p">{`strict: true`}</inlineCode>{` to your tsconfig.json, stray from `}<inlineCode parentName="p">{`any`}</inlineCode>{` and now you’re
forced to maintain a decent level of type safety. Also, it’s pretty obvious,
even before a morning coffee, that it has no runtime cost.`}</p>
    <h3 {...{
      "id": "typing-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#typing-functions",
        "aria-label": "typing functions permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a><em parentName="h3">{`typing functions?`}</em></h3>
    <p><inlineCode parentName="p">{`(selected: Video) => void`}</inlineCode>{`. Pretty easy, amiright? Programming, even OOP,
is mostly about using functions to do stuff. Ability to describe the type of
a function is quite useful.`}</p>
    <h3 {...{
      "id": "optional-by-default",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#optional-by-default",
        "aria-label": "optional by default permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a><em parentName="h3">{`optional by default?`}</em></h3>
    <p>{`In TypeScript, you gotta stick this `}<inlineCode parentName="p">{`?`}</inlineCode>{` every time you want an optional
property.`}</p>
    <figure>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "ts"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` Car `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  registrationNumber`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`string`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  year`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`?:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`number`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`};`}</span></span></code></pre>
      <figcaption>We deal only with registered cars up in here.</figcaption>
    </figure>
    <p>{`And look at what else we get!`}</p>
    <h3 {...{
      "id": "componentpropsbutton",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#componentpropsbutton",
        "aria-label": "componentpropsbutton permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`ComponentProps<`}<inlineCode parentName="h3">{`"`}</inlineCode>{`button`}<inlineCode parentName="h3">{`"`}</inlineCode>{`>`}</h3>
    <p>{`I could talk about subtyping and Liskov Substitution Principle, but I’ll
simplify it a little bit. `}<strong parentName="p">{`If it’s a button, it should be buttony.`}</strong><br parentName="p"></br>{`
`}{`Props you expect on a button should be accepted by all of your design system
buttons. What do I expect? At least `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`, `}<inlineCode parentName="p">{`onFocus`}</inlineCode>{`, `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`className`}</inlineCode>{`,
and `}<inlineCode parentName="p">{`style`}</inlineCode>{`. We can handle all attributes of HTML `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button"
      }}><inlineCode parentName="a">{`<button>`}</inlineCode></a>{`
element, including all `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes"
      }}>{`global attributes`}</a>{`
with a simple `}<a parentName="p" {...{
        "href": "https://reactpatterns.com/#jsx-spread-attributes"
      }}>{`spread`}</a></p>
    <figure>
      <pre {...{
        "className": "night-owl-no-italics vscode-highlight",
        "data-language": "tsx"
      }}><code parentName="pre" {...{
          "className": "vscode-highlight-code"
        }}><span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` { ComponentProps, FC } `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk18"
            }}>{`react`}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`type`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` ButtonVariant `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk18"
            }}>{`default`}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`|`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk18"
            }}>{`call-to-action`}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}></span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`interface`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` ButtonProps `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`extends`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk5"
            }}>{`ComponentProps`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk18"
            }}>{`button`}</span><span parentName="span" {...{
              "className": "mtk36"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`> {`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  variant`}</span><span parentName="span" {...{
              "className": "mtk9"
            }}>{`?:`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`ButtonVariant`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
            "className": "vscode-highlight-line"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`}`}</span></span></code></pre>
      <figcaption>
        <p><a parentName="p" {...{
            "href": "https://codesandbox.io/embed/github/hasparus/componentprops-example/tree/master/"
          }}>{`See more on CodeSandbox`}</a></p>
      </figcaption>
    </figure>
    <p>{`Do you want `}<strong parentName="p">{`your Button`}</strong>{` to be inferior to `}<strong parentName="p">{`a button`}</strong>{`? I don’t think
so.`}</p>
    <h3 {...{
      "id": "omitlinkprops-to",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#omitlinkprops-to",
        "aria-label": "omitlinkprops to permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Omit<LinkProps, `}<inlineCode parentName="h3">{`"`}</inlineCode>{`to`}<inlineCode parentName="h3">{`"`}</inlineCode>{`>`}</h3>
    <p>{`But what if my component comes “batteries included” and I don’t want to
accept all props of the component I’m building upon?`}<br parentName="p"></br>{`
`}{`Only like… most of them? We can Omit what we don’t like. Just like that.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` JoinMeetingButton`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Omit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ButtonProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`onClick`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Pick`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Meeting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`id`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {}`}</span></span></code></pre>
    <h3 {...{
      "id": "union-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#union-types",
        "aria-label": "union types permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Union Types`}</h3>
    <p>{`The anchors and the buttons often look the same in the mockups, but they are
different kinds of animals. We want to reuse the styling and behavior
between them and make choosing the right one for the job effortless.`}</p>
    <p>{`We can use `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/advanced-types.html#union-types"
      }}>{`union types`}</a>{` to build a Button component which renders an
anchor, given a `}<inlineCode parentName="p">{`href`}</inlineCode>{` prop and renders a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` otherwise.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { ComponentProps } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`react`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ButtonAsAnchorProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`ComponentProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`a`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  href`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`interface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ButtonAsButtonProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`ComponentProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`button`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  href`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`?:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ButtonProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ButtonAsAnchorProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ButtonAsButtonProps`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`function`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`Button`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`{ className: `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`propsClassName`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`rest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`ButtonProps`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`className`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` =`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`Button`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` propsClassName]`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`join`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (rest`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`href`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!==`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<a `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`className`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`className`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`rest`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` />`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<button `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`className`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`className`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`rest`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` />`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <h3 {...{
      "id": "pickmeeting-date--organizer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pickmeeting-date--organizer",
        "aria-label": "pickmeeting date  organizer permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Pick<Meeting, `}<inlineCode parentName="h3">{`"`}</inlineCode>{`date`}<inlineCode parentName="h3">{`"`}</inlineCode>{` | `}<inlineCode parentName="h3">{`"`}</inlineCode>{`organizer`}<inlineCode parentName="h3">{`"`}</inlineCode>{`>`}</h3>
    <p>{`We can select properties from our types with `}<inlineCode parentName="p">{`Pick`}</inlineCode>{`.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` MeetingInfoProps `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Pick`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Meeting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`organizer`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`MeetingInfo`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`{ `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`organizer`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`MeetingInfoProps`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`new`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Date`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(date)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`toLocaleString`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` • `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`organizer`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span></code></pre>
    <p>{`Imagine that `}<em parentName="p">{`Meeting`}</em>{` is a type of data we get from the backend. We want to
show MeetingInfo — a date and organizer of the meeting and we don’t really
care about the type of these `}<inlineCode parentName="p">{`date`}</inlineCode>{` and `}<inlineCode parentName="p">{`organizer`}</inlineCode>{` props. We care about
their origin. They come from the `}<em parentName="p">{`Meeting`}</em>{` type and that’s what’s important
for this component. Will this component break when the representation of our
meetings change? Yes. And we want it to. Also,`}{` `}<a parentName="p" {...{
        "href": "https://www.swyx.io/writing/how-to-name-things/#not-naming-things-aug-2019-edit"
      }}>{`we`}{` `}{`avoid
introducing new names`}</a><br parentName="p"></br>{`
`}{`(e.g.`}<inlineCode parentName="p">{`<MeetingInfo author={meeting.organizer} />`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#summary",
        "aria-label": "summary permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Summary`}</h2>
    <p>{`PropTypes are not `}<em parentName="p">{`first class`}</em>{`. They’re a library trying to implement what
is often a language feature. If you’re building an app, you don’t need
runtime typechecking. Try swapping `}<inlineCode parentName="p">{`prop-types`}</inlineCode>{` for TypeScript or Flow and
`}<a parentName="p" {...{
        "href": "https://twitter.com/hasparus"
      }}>{`tweet me`}</a>{` what you think.`}</p>
    <p>{`You can see the types I’ve written about used together in the sandbox below.`}</p>
    <CodesandboxIframe src="https://codesandbox.io/embed/github/hasparus/componentprops-omit-tagged-union-button/tree/master/?fontsize=14&hidenavigation=1&runonclick=1" title="componentprops-omit-and-tagged-union" mdxType="CodesandboxIframe" />

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      